import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { AppConfig } from '../app.config';

import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import { HttpClient } from '@angular/common/http';

import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';


@Injectable()
export class AuthService {
    public token?: string;

    public static CURRENT_USER_KEY = "cjit_voting_user"

    public currentUser: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private http: HttpClient, 
        private appConfig: AppConfig,
        private router: Router) {
        this.appConfig.configLoaded.subscribe(config => {     
            if (config) {
                const savedUser = localStorage.getItem(AuthService.CURRENT_USER_KEY);
                const userInfo = savedUser ? JSON.parse(savedUser) : null;
                if (userInfo) {
                    this.currentUser.next(userInfo);
                    this.token = userInfo && userInfo.token;
                }
            }   

            
        });
    }

    login(email: string, password: string): Observable<boolean | null> {

        const result = new BehaviorSubject<boolean | null>(null);
        this.http.post(AppConfig.API_URL + 'auth', { email: email, password: password })
            .subscribe(async (response: any) => {                
                const token = response.token;
                if (token) {
                    this.token = token;           
                    const decodedToken: any = jwt_decode(token);
                    if (!decodedToken || !decodedToken['roles']) {
                        result.next(false);
                    }
                                                 
                    const currentUser = { 
                        email: email, 
                        company_name: decodedToken['company'],
                        full_name: decodedToken['full_name'],
                        token: token, 
                        roles: decodedToken['roles']
                    };
                    this.currentUser.next(currentUser);
                    localStorage.setItem(AuthService.CURRENT_USER_KEY, JSON.stringify(currentUser));                    
                    result.next(true);
                    
                } else {
                    result.next(false);
                }
            }, (error) => {                                
                result.error(error.error)
            });

        return result;
    }

    

    logout(): void {
        localStorage.removeItem(AuthService.CURRENT_USER_KEY);
        delete this.token;
        this.currentUser.next(null);  
        this.router.navigate(["login"]);
    }
}
