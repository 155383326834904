<main class="form-signin" *ngIf="currentUser">

    <h6>Bun venit, {{currentUser.full_name}} ({{currentUser.company_name}}) !</h6><br/><br/>
    <h5>ClujIT Cluster - Sesiuni Vot Active</h5><br/>    
    <ul class="list-group" *ngIf="sessions?.length">
        <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let session of sessions" style="text-align: left;">
          <a [routerLink]="['ses',session.key]" class="flex-grow-1">{{session.name}}             
          </a>
          <!-- <span *ngIf="isAdmin" style="color:gray; margin-right: 10px;">[ {{session.session_status}} ]</span> -->

          <span style="color:lightgray; padding: 0 10px 0 10px;" *ngIf="session.session_status === 'pending'">[&nbsp;&nbsp;Sesiune&nbsp;Vot&nbsp;In&nbsp;Asteptare&nbsp;&nbsp;]</span>
          <span style="color:lightgray; padding: 0 10px 0 10px;" *ngIf="session.session_status === 'started'">[&nbsp;&nbsp;Sesiune&nbsp;Vot&nbsp;Deschisa&nbsp;&nbsp;]</span>
          <span style="color:lightgray; padding: 0 10px 0 10px;" *ngIf="session.session_status === 'ended'">[&nbsp;&nbsp;Sesiune&nbsp;Vot&nbsp;Finalizata&nbsp;&nbsp;]</span>
          <span style="color:lightgray; padding: 0 10px 0 10px;"*ngIf="session.session_status === 'draft'">[&nbsp;&nbsp;Sesiune&nbsp;Vot&nbsp;Inchisa&nbsp;&nbsp;]</span>
    

          <span class="badge badge-success" width="16" height="16" *ngIf="session.session_status === 'draft'">
            <img src="/assets/icons/clock.svg" alt="Bootstrap" width="16" height="16" >
          </span>
          <span class="badge badge-primary" width="16" height="16" *ngIf="session.session_status === 'pending'">
              <img src="/assets/icons/lock.svg" alt="Bootstrap" width="16" height="16" >
          </span>
          <span class="badge badge-info" width="16" height="16" *ngIf="session.session_status === 'started'">
            <img src="/assets/icons/unlock.svg" alt="Bootstrap" width="16" height="16" >
          </span>

          <span class="badge badge-success" width="16" height="16" *ngIf="session.session_status === 'ended'">
            <img src="/assets/icons/bookmark-check.svg" alt="Bootstrap" width="16" height="16" >
          </span>

          
          
          <a href="javascript:;"  class="btn btn-sm btn-primary" (click)="enableSession(session)" *ngIf="isAdmin && session.session_status === 'draft'" style="margin-left: 10px;">            
            <img src="/assets/icons/check.svg" alt="Bootstrap" width="16" height="16">
          </a>
          
          

          <a href="javascript:;"  class="btn btn-sm btn-primary" (click)="startSession(session)" *ngIf="isAdmin && session.session_status === 'pending'" style="margin-left: 10px;">            
            <img src="/assets/icons/play.svg" alt="Bootstrap" width="16" height="16">            
          </a>

          <a href="javascript:;"  class="btn btn-sm btn-primary" (click)="stopSession(session)" *ngIf="isAdmin && session.session_status === 'started'" style="margin-left: 10px;">            
            <img src="/assets/icons/stop-circle.svg" alt="Bootstrap" width="16" height="16">            
          </a>
<!-- 
          <a href="javascript:;"  class="btn btn-sm btn-primary" (click)="restart(session)" *ngIf="isAdmin && session.session_status === 'ended'" style="margin-left: 10px;">            
            <img src="/assets/icons/play.svg" alt="Bootstrap" width="16" height="16">            
          </a> -->

          
          <!--
          <! -- Started -- > 
          <! -- Started and not voted -- > <span class="badge badge-warning"><img src="/assets/icons/bookmark.svg" alt="Bootstrap" width="16" height="16"></span>
          <! -- Started and voted -- > <span class="badge badge-success"><img src="/assets/icons/bookmark-check.svg" alt="Bootstrap" width="16" height="16" *ngIf="session.session_status === 'draft'"></span>
          <! -- Closed and not voted -- > <span class="badge badge-danger"><img src="/assets/icons/bookmark-x.svg" alt="Bootstrap" width="16" height="16"></span>
        -->
        </li>        
      </ul>
      <div class="alert alert-dismissible alert-light" *ngIf="!sessions?.length">
        
        <strong>Nu aveti nicio sesiune de vot activa!</strong>
      </div>      
    </main>