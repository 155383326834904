import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionModel } from '../models/session.model';
import { SessionsService } from '../services/sessions.service';
import { SetupService } from '../services/setup.service';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  sessions!: SessionModel[];

  loading = true;

  isAdmin = false;

  currentUser: any;

  refreshInterval?: any;

  constructor(
        private sessionsService: SessionsService, 
        private authService: AuthService,
        private setupService: SetupService) {
    this.authService.currentUser.subscribe(user => {
      this.isAdmin = user && user.roles && user.roles.indexOf('admin') >= 0;
      this.currentUser = user;
    })
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshInterval);
  }

  ngOnInit(): void {   
    this.loadData();

    this.refreshInterval = setInterval( () => {
      this.loadData();
    }, 2000);
  }

  private loadData() {
    this.sessionsService.getRecords().then(items => {
      if (!items) {
        return;
      }
      if (items?.data) {
        this.sessions = items?.data;
      }
      this.loading = false;
    });
  }

  async enableSession(session: SessionModel) {
    if (confirm("Ești sigur că dorești să activezi această sesiune de vot? \r\n\r\nOdata activata, sesiunea va fi vizibila tututor utilizatorilor")) {
      const response = await this.setupService.runCommand("enable-session", {session_id: session.id});
      if (response.success) {
        this.loadData();
      }
      else {
        alert(response.message);
      }
    }
  }

  async startSession(session: SessionModel) {
    if (confirm("Ești sigur că dorești să deschizi această sesiune de vot? \r\n\r\nOdata deschisa sesiunea, fiecare subiect trebuie deschis/inchis separat.")) {
      const response = await this.setupService.runCommand("start-session", {session_id: session.id});
      if (response.success) {
        this.loadData();
      }
      else {
        alert(response.message);
      }
    }
  }

  async stopSession(session: SessionModel) {
    if (confirm("Ești sigur că dorești să inchizi această sesiune de vot?\r\n\r\nOdata inchisa sesiunea, niciunul dintre subiectele de vot ramase nu se vor mai putea vota!")) {
      const response = await this.setupService.runCommand("stop-session", {session_id: session.id});
      if (response.success) {
        this.loadData();
      }
      else {
        alert(response.message);
      }
    }
  }

}
