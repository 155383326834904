import { HttpClient } from "@angular/common/http";
import { BaseAPIService } from "./base.api-service";
import { Injectable } from "@angular/core";
import { SessionModel } from "../models/session.model";
import { AppConfig } from "../app.config";
import { AuthService } from "../shared/auth.service";

@Injectable({ providedIn: 'root' })
export class SessionsService extends BaseAPIService<SessionModel> {

    constructor(httpClient: HttpClient, appConfig: AppConfig, authService: AuthService) {
        super(httpClient, true, appConfig, authService);        
        this.defaultSortField = "name";

        
        this.path = "voting-sessions";
    }    
}