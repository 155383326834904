import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionModel } from '../models/session.model';
import { SubjectModel } from '../models/subject.model';
import { SessionsService } from '../services/sessions.service';
import { SetupService } from '../services/setup.service';
import { SubjectsService } from '../services/subjects.service';
import { UserSubjectsService } from '../services/user-subjects.service';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.css']
})
export class SessionComponent implements OnInit, OnDestroy {

  selectedSession?: SessionModel;
  isAdmin = false;
  refreshInterval?: any;
  currentUser: any;

  constructor(
    private sessionsService: SessionsService,
    private subjectsService: SubjectsService,
    private authService: AuthService,
    private setupService: SetupService,
    private userSubjectsService: UserSubjectsService,
    private route: ActivatedRoute    
  ) { 
    this.authService.currentUser.subscribe(user => {
      this.isAdmin = user && user.roles && user.roles.indexOf('admin') >= 0;
      this.currentUser = user;
    })
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshInterval);
  }

  ngOnInit(): void {

    this.route.params.subscribe(async params => {
      
      if (params && params['key']) {                
        const session = await this.sessionsService.getRecordBy("key", params['key']);        
        if (session) {          
          session.subjects = await this.subjectsService.getRecordsBy("session_id", session.id);
          this.selectSession(session);  
          
          if (!this.isAdmin) {
            const userSubjects = await this.userSubjectsService.getRecordsBy("session_id", session.id);
            userSubjects.filter(it => it.voted).forEach( userSubject => {
              const subject = session.subjects.find(it => it.id === userSubject.subject_id);
              if (subject) {
                subject.subject_status = "voted"
                subject.total_votes = 0;
                if (this.isAdmin && subject.voting_counter) {
                  for (let option of subject.voting_counter) {
                    subject.total_votes += subject.voting_counter[option];
                  }
                }
                
              }            
            })
          } 
          
          this.refreshInterval = setInterval( () => {
            this.loadData();
          }, 2000);
        }        
      }
    });

  }

  selectSession(session: SessionModel) {    
    this.selectedSession = session;
    this.sessionsService.selectRecord(session);
  }

  async loadData() {
    if (this.selectedSession?.key) {
      const session = await this.sessionsService.getRecordBy("key", this.selectedSession?.key);        
      if (session) {          
        const subjects = await this.subjectsService.getRecordsBy("session_id", session.id);

        if (!this.isAdmin) {
          const userSubjects = await this.userSubjectsService.getRecordsBy("session_id", session.id);
          userSubjects.filter(it => it.voted).forEach( userSubject => {
            const subject = subjects.find(it => it.id === userSubject.subject_id);
            if (subject) {
              subject.subject_status = "voted";
              
            }            
          });          
        } else {
          subjects.forEach(subject => {
            
            subject.total_votes = 0;
            
            if (this.isAdmin && subject.voting_counter) {
              for (let option of Object.getOwnPropertyNames(subject.voting_counter)) {                
                subject.total_votes += subject.voting_counter[option];
              }
            }
          });
        }

        session.subjects = subjects;

        this.selectSession(session);  
      }  
    }    
  }

  async startSubject(subject: SubjectModel) {
    if (confirm("Ești sigur că dorești să deschizi acest subiect pentru vot?")) {
      const response = await this.setupService.runCommand("start-subject", {subject_id: subject.id});
      if (response.success) {
        this.loadData();
      }
      else {
        alert(response.message);
      }
    }
    
  }
  

  async stopSubject(subject: SubjectModel) {
    if (confirm("Ești sigur că dorești să inchizi votul pentru acest subiect?")) {
      const response = await this.setupService.runCommand("stop-subject", {subject_id: subject.id});
      if (response.success) {
        this.loadData();
      }
      else {
        alert(response.message);
      }
    }
  }
}
