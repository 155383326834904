import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { SessionComponent } from './session/session.component';
import { AuthGuard } from './shared/auth.guard';
import { SubjectComponent } from './subject/subject.component';

const routes: Routes = [
    { path: '', component: DashboardComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'ses/:key', component: SessionComponent, pathMatch: 'full', canActivate: [AuthGuard]},     
    { path: 'sbj/:key', component: SubjectComponent, pathMatch: 'full', canActivate: [AuthGuard]},     
    { path: "login", component: LoginComponent, pathMatch: 'full' }    
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
