import { HttpClient } from "@angular/common/http";
import { BaseAPIService } from "./base.api-service";
import { Injectable } from "@angular/core";
import { AppConfig } from "../app.config";
import { AuthService } from "../shared/auth.service";
import { SubjectOptionModel } from "../models/subject-option.model";

@Injectable({ providedIn: 'root' })
export class SubjectOptionsService extends BaseAPIService<SubjectOptionModel> {

    constructor(httpClient: HttpClient, appConfig: AppConfig, authService: AuthService) {
        super(httpClient, false, appConfig, authService);        
        this.defaultSortField = "created_date";

        
        this.path = "voting-options";
    }    
}