import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {     
  }

  canActivate(): boolean {   
    if (localStorage.getItem(AuthService.CURRENT_USER_KEY)) {
          return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}