
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/Rx';

class AppConfigInfo {
  API_URL?: string;
  APP_URL?: string;
  version?: string;
}




@Injectable()
export class AppConfig {
  static API_URL? = "https://api.vot.clujit.ro/";  //'http://localhost:3000/';  
  static APP_URL? = '';
  static version? = '';

  public configLoaded: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
    this.checkAppVersion();
    setInterval(() => {
      this.checkAppVersion();
    }, 60000);

  }
  checkAppVersion() {
    console.log('Loading configuration');
    this.http.get<AppConfigInfo>('/config.json?' + new Date().getTime(), {observe: 'body'}).subscribe(it => {
    
      if (!AppConfig.version) {        
        console.log('Configuration loaded:', it);        
        AppConfig.version = it.version;
        AppConfig.API_URL = it['API_URL'];        
        AppConfig.APP_URL = it['APP_URL'];
        this.configLoaded.next(AppConfig);
      }
      else if (it['version'] !==  AppConfig.version) {
        console.log('Configuration updated:', it);        
        
        AppConfig.version = it.version;
        AppConfig.API_URL = it.API_URL;        
        AppConfig.APP_URL = it.APP_URL;
        this.configLoaded.next(AppConfig);      
      }
    });
  }

}
