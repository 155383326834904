import { HttpClient } from "@angular/common/http";
import { BaseAPIService } from "./base.api-service";
import { Injectable } from "@angular/core";
import { SessionModel } from "../models/session.model";
import { AppConfig } from "../app.config";
import { AuthService } from "../shared/auth.service";
import { SubjectModel } from "../models/subject.model";
import { UserSubjectModel } from "../models/user-subject.model";

@Injectable({ providedIn: 'root' })
export class UserSubjectsService extends BaseAPIService<UserSubjectModel> {

    constructor(httpClient: HttpClient, appConfig: AppConfig, authService: AuthService) {
        super(httpClient, false, appConfig, authService);        
        this.defaultSortField = "created_date";

        
        this.path = "subject-users";
    }    
}