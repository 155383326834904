<nav class="navbar navbar-expand-lg navbar-light bg-primary white-text" *ngIf="currentUser">
  <div class="container-fluid">
    <a class="navbar-brand" href="#"><img src="https://www.clujit.ro/wp-content/uploads/2020/02/logo-1.png?x93400" height="32" alt=""></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent" >
      
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Dashboard</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/login">Deconectare</a>
        </li>              
      </ul>           
    </div>
  </div>
</nav>

  <router-outlet></router-outlet>  
  