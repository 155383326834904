import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, throwError } from "rxjs";
import { catchError, retry } from 'rxjs/operators';
import { AppConfig } from "../app.config";
import { BaseAPIResponseModel } from "../models/base.api-reponse.model";
import { BaseAPIModel } from "../models/base.api.model";
import { AuthService } from "../shared/auth.service";

export type FiltersList = { [index: string]: any };

export abstract class BaseAPIService<T extends BaseAPIModel> {

    private _path!: string;

    public defaultSortField!: string;

    public records = new BehaviorSubject<BaseAPIResponseModel<T> | null>(null);

    public currentRecord = new BehaviorSubject<T | null>(null);

    private headers = new HttpHeaders();

    private token?: string;

    private preloadAfterAuth = false;

    constructor(private httpClient: HttpClient, private subscribable = false, private appConfig: AppConfig, private authService: AuthService) {
        
        this.authService.currentUser.subscribe((user) => {            
            if (user) {
                this.token = user.token;                
                this.headers = new HttpHeaders();
                this.headers = this.headers.append('x-auth-token', this.token || "");
                this.headers = this.headers.append('Content-Type', 'application/json');

                if (this.preloadAfterAuth) {
                    this.preload();
                }

            }
        });   
    }

    protected get path(): string {
        return this._path;
    }

    protected set path(value: string) {
        this._path = value;

        if (this.subscribable) {
            if (!this.token) {
                this.preloadAfterAuth = true;
            } else {
                this.preload();
            }
        }
    }

    public async getRecord(recordID: string): Promise<T> {
        let url = AppConfig.API_URL + this._path + '/' + recordID + "/";
        const result = this.httpClient.get<T>(url, { headers: await this.getDefaultHeaders() }).pipe(retry(1), catchError(this.handleError)).toPromise();

        return result;
    }

    public async getRecordBy(key: string, value: string): Promise<T | void> {
        const filters: FiltersList = {};

        filters[key] = value;
        const records = await this.getRecords(0, 1, 'created_date', false, filters);

        if (records?.data.length === 1) {
            return records.data[0];
        }
    }

    public async getRecordsBy(key: string, value: string): Promise<T[]> {
        const filters: FiltersList = {};

        filters[key] = value;
        const records = await this.getRecords(0, 100, 'created_date', false, filters);

        return records.data;
        
    }

    public async getRecords(
        offset = 0,
        limit = 100,
        sortField: string = '',
        sortASC: boolean = true,
        filters: FiltersList = {}
    ): Promise<BaseAPIResponseModel<T>> {

        let url = AppConfig.API_URL + this._path + '?';
        const queryParams = [];

        if (offset > 0) {
            queryParams.push(`offset=${offset}`);
        }

        if (limit > 0) {
            queryParams.push(`limit=${limit}`);
        }

        if (sortField) {
            queryParams.push(`sort=${sortASC ? '' : '-'}${sortField}`);
        } else if (this.defaultSortField) {
            queryParams.push(`sort=${sortASC ? '' : '-'}${this.defaultSortField}`);
        }

        if (filters) {
            let query = "";
            const properties = Object.getOwnPropertyNames(filters);
            properties.forEach(property => {
                if (query) {
                    query += " and ";
                }
                query += property + " eq " + filters[property];
            });

            if (query) {
                queryParams.push(`query=${query}`);
            }


        }

        queryParams.push(`_dt=${new Date().getTime()}`);
        url += queryParams.join('&');

        const result = this.httpClient.get<BaseAPIResponseModel<T>>(url, { headers: await this.getDefaultHeaders() }).pipe(retry(3), catchError(this.handleError)).toPromise();

        return result;
    }

    public async createRecord(record: Partial<T>): Promise<T | void> {
        const url = AppConfig.API_URL + this._path + '/';
        const result = this.httpClient.post<T | void>(url, record, { headers: await this.getDefaultHeaders() }).pipe(catchError(this.handleError)).toPromise();

        return result;
    }


    public async updateRecord(id: number, record: Partial<T>): Promise<T | void> {
        const url = AppConfig.API_URL + this._path + '/' + id;

        const result = this.httpClient.put<T | void>(url, record, { headers: await this.getDefaultHeaders() }).pipe(catchError(this.handleError)).toPromise();

        return result;
    }

    public async deleteRecord(id: number): Promise<unknown> {
        const url = AppConfig.API_URL + this._path + '/' + id;
        const result = this.httpClient.delete<void>(url, { headers: await this.getDefaultHeaders() }).pipe(catchError(this.handleError)).toPromise();

        return result;
    }

    public async runCommand(command: string, body: unknown): Promise<any> {
        const url = AppConfig.API_URL + this._path + '/' + command;
        const result = this.httpClient.post<any>(url, body, { headers: await this.getDefaultHeaders() }).pipe(catchError(this.handleError)).toPromise();

        return result;
    }

    public selectRecord(record: T) {
        this.currentRecord.next(record);
    }

    protected handleError(response: HttpErrorResponse) {
        if (response.error?.errors) {
            return throwError({ message: response.error?.message, errors: response.error.errors });
        }
        else if (response.error?.message) {
            return throwError({ message: response.error?.message });
        }        
        return throwError({ message: BaseAPIService.getServerErrorMessage(response) });
    }

    protected static getServerErrorMessage(error: HttpErrorResponse): string {

        switch (error.status) {
            case 0: {
                return `Connection refused: ${error.message}`;
            }
            case 404: {
                return `Not Found: ${error.message}`;
            }
            case 403: {
                return `Access Denied: ${error.message}`;
            }
            case 500: {
                return `Internal Server Error: ${error.message}`;
            }
            default: {
                return `Unknown Server Error: ${error.message}`;
            }

        }
    }

    protected async  getDefaultHeaders(): Promise<HttpHeaders> {           
        return new Promise<HttpHeaders> ( async (resolve, reject) => {
            if (this.headers.keys().length > 0) {                   
                return resolve(this.headers);
            } else {
                setTimeout(async () => {                    
                    resolve( await this.getDefaultHeaders());
                }, 100)
            }
        })
          
        
    }

    private async preload() {

        this.appConfig.configLoaded.subscribe(async (data) => {
            if (data) {
                try {
                    let result = await this.getRecords();
                    this.records.next(result);                    
                } catch (ex: unknown) {
                    console.log("Records could not be loaded:", this.path, ex);
                }

            }
        });
    }
}