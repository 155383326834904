import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionModel } from '../models/session.model';
import { SubjectOptionModel } from '../models/subject-option.model';
import { SubjectModel } from '../models/subject.model';
import { SessionsService } from '../services/sessions.service';
import { SetupService } from '../services/setup.service';
import { SubjectOptionsService } from '../services/subject-options.service';
import { SubjectsService } from '../services/subjects.service';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.css']
})
export class SubjectComponent implements OnInit, OnDestroy {

  selectedSubject?: SubjectModel;
  selectedSession?: SessionModel;

  isAdmin = false;

  selectedValue?: unknown;
  selectedValues: boolean[]= [];  
  selectedValuesLength: number = 0;  

  winningOption?: SubjectOptionModel;

  totalVotesCount?: number;
  totalVotesScore?: number;
  refreshInterval: any;
  currentUser: any;
  constructor(
    private router: Router,
    private subjectsService: SubjectsService,
    private sessionsService: SessionsService,
    private subjectOptionsService: SubjectOptionsService,
    private authService: AuthService,
    private setupService: SetupService,
    private route: ActivatedRoute    
  ) {
    this.authService.currentUser.subscribe(user => {
      this.isAdmin = user && user.roles && user.roles.indexOf('admin') >= 0;
      this.currentUser = user;
    })
   }

  ngOnDestroy(): void {
    clearInterval(this.refreshInterval);
  }

  ngOnInit(): void {

    this.route.params.subscribe(async params => {
      if (params && params['key']) {                
        await this.loadData(params['key']); 
        
        if (this.isAdmin) {
          this.refreshInterval = setInterval(() => {
            this.loadData(params['key'])
          }, 1000);
        }
      }
    });
  }

  

  private async loadData(key:string) {

    const subject = await this.subjectsService.getRecordBy("key", key);    
    if (subject) {
      this.selectedSession = await this.sessionsService.getRecord(subject.session_id);
      subject.options = await this.subjectOptionsService.getRecordsBy("subject_id", subject.id);
      if (!this.isAdmin) {
        subject.options = subject.options.filter( it => it.name.indexOf("Nedefinit") < 0);
      }

      if (subject.maximum_selections > 0) {
        this.selectedValues = subject.options.map( it => false);
      }

      if (this.isAdmin) {
        this.totalVotesCount = 0;
        this.totalVotesScore = 0;

        if (!subject.voting_counter) {
          subject.voting_counter = {};
        }
        if (!subject.voting_results) {
          subject.voting_results = {};
        }
        for (let key in subject.options) {
          this.totalVotesCount += subject.voting_counter?.[subject.options[key].name] || 0;
        }
        for (let key in subject.options) {
          this.totalVotesScore += subject.voting_results?.[subject.options[key].name] || 0;
        }

        let maxScore = -1;
        for (let key in subject.options) {
          const optionScore = subject.voting_results?.[subject.options[key].name];
          if (optionScore > maxScore) {
            maxScore = subject.voting_results?.[subject.options[key].name];
            this.winningOption = subject.options[key];
          } else if (optionScore === maxScore) {
            delete this.winningOption;
          }
        }
      }
      this.selectSubject(subject);
    }
  }

  toggleValue(element: number) {    
    this.selectedValues[element] = !this.selectedValues[element];
    this.selectedValuesLength = this.selectedValues.filter(it => it === true).length;
  }

  selectSubject(subject: SubjectModel) {    
    this.selectedSubject = subject;
    this.subjectsService.selectRecord(subject);
  }

  async sendVote() {
    if (this.selectedSubject?.id && this.selectedValue) {
        const response = await this.setupService.runCommand("vote", {subject_id: this.selectedSubject.id, option: this.selectedValue});
        if (response.success) {
          this.router.navigate(['/ses', this.selectedSession?.key]); 
        }
        else {
          alert(response.message);
        }
    } else if (this.selectedSubject?.id && this.selectedSubject?.maximum_selections > 0 && this.selectedValues && this.selectedSubject.maximum_selections <= this.selectedValues.length) {
        const selectedOptions = [];
        for (let index = 0; index < this.selectedValues.length; index++) {
          if ( this.selectedValues[index] ) {
            selectedOptions.push(this.selectedSubject.options[index].name);
          }
        }
        
        const response = await this.setupService.runCommand("vote", {subject_id: this.selectedSubject.id, options: selectedOptions});
        if (response.success) {
          this.router.navigate(['/ses', this.selectedSession?.key]); 
        }
        else {
          alert(response.message);
        }    
    }
  }

  async editOption(option: SubjectOptionModel) {
    const newValue = prompt("Te rugam sa introduci noua valoare a optiunii de vot", option.name);
    if (newValue && newValue !== option.name) {      
      const response = await this.setupService.runCommand("update-option", { subject_id: this.selectedSubject?.id, option_id: option.id, value: newValue});
      if (response.success && this.selectedSession?.key) {
        this.loadData(this.selectedSession?.key); 
      }
      else {
        alert(response.message);
      }
      
    }

    
  }
}
