import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../app.config';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    email: string = "";

    password: string = "";

    error: string = '';

    loading =  true;

    constructor(private router: Router,
                private authenticationService: AuthService,
                //private profileService: ProfileService,
                private appConfig: AppConfig) {

        this.appConfig.configLoaded.subscribe(config => {
            if (config) {
                this.loading = false;
            }                   
        });
                    
    }

    ngOnInit() {    
        this.authenticationService.logout();
    }

    login() {
        this.loading = true;
        this.authenticationService.login(this.email, this.password)
            .subscribe(result => {
                if (result === null) {
                    return;
                }            
                console.log('Login Component -> recieved result:', JSON.stringify(result, null, 2));
                if (result === true) {
                    this.error = '';       
                    this.loading = false;                             
                    this.router.navigate(['/']); 
                    
                    // this.profileService.userProfile.subscribe( (profile) => {                        
                    //     this.error = '';                                    
                    //     this.loading = false;
                    //     this.router.navigate(['/session']);                    
                    // });                    
                } else {
                    this.loading = false;
                    this.error = "Authentication Error";
                }
            }, error => {                        
                this.loading = false;                
                this.error = error.message;                
            });
    }

}
