<main class="form-signin">
<form  (ngSubmit)="login()" name="loginForm">
    <img class="mb-4" src="https://www.clujit.ro/wp-content/uploads/2020/02/logo-1.png?x93400" alt="">
    <h1 class="h3 mb-3 fw-normal">Sistem Vot</h1><br/>
    <h2 class="h4 mb-3 fw-normal">Va rugam sa va autentificati</h2>
  
    <div class="form-floating">    
      <input name="email" type="email" class="form-control" id="floatingInput" placeholder="nume.prenume@domeniu.ro" [(ngModel)]="email" required>
      
    </div>
    <div class="form-floating">
      <input  name="password"  type="password" class="form-control" id="floatingPassword" placeholder="parola"  [(ngModel)]="password">    
    </div>
    <div class="alert alert-dismissible alert-danger" *ngIf="error">      
      <h6 class="alert-heading">{{error}}</h6>      
    </div>
    <button class="w-100 btn btn-lg btn-primary" type="submit">Autentificare</button>
    <p class="mt-5 mb-3 text-muted">&copy; 2021 | CLUJIT Cluster</p>
  </form>
  </main>