<main class="form-signin" *ngIf="selectedSession && selectedSubject">
    <h5>{{selectedSession.name}}</h5><br/>
    <h6 style="color:slategrey">{{currentUser.full_name}} ({{currentUser.company_name}})</h6><br/><br/>

<div class="card border-primary mb-3" style="max-width: 100%;">
    <div class="card-header">{{selectedSubject.name}}</div>
    <div class="card-body">            
      <div class="card-text" style="text-align: center;"  *ngIf="isAdmin">
        <h4 *ngIf="selectedSubject.subject_status !== 'ended'">Rezultate partiale</h4>
        <h4 *ngIf="selectedSubject.subject_status === 'ended'">Rezultate finale</h4>
        <br/>        
        <table width="100%" class="table">
          <tr>
            <th colspan="2">Optiune</th>
            <th>Voturi Exprimate</th>
            <th>% Voturi</th>
            <th>Scor Total</th>            
            <th>% Scor</th>
          </tr>
          <tr *ngFor="let option of selectedSubject.options">
            <td style="text-align:left;"><br/>              
              <span style="color: lightslategray;">
                {{option.name}}
              </span>
              <a href="javascript:;" (click)="editOption(option)"  *ngIf="selectedSubject.maximum_selections > 0">
                <span class="badge badge-success" style="margin-left:30px;" width="16" height="16">
                  <img src="/assets/icons/tools.svg" alt="Bootstrap" width="16" height="16" >
                </span> 
              </a>
              <br/>
              <br/>
            </td>
            <td width="50" style="text-align:center;"><br/>
              <span class="badge badge-success" style="margin-left:30px;" width="16" height="16" *ngIf="winningOption?.id === option.id">
                <img src="/assets/icons/trophy.svg" alt="Bootstrap" width="16" height="16" >
              </span>         <br/>      
            </td>
            <td>
              <br/>
                {{selectedSubject.voting_counter[option.name]}} <br/>
            </td>
            
            <td><br/>
              {{(totalVotesCount && totalVotesCount > 0 && selectedSubject.voting_counter[option.name] ? selectedSubject.voting_counter[option.name] * 100 / totalVotesCount : '0') | number: '1.0-2'}} %
              <div class="progress" *ngIf="totalVotesCount && totalVotesCount > 0 && selectedSubject.voting_counter[option.name]">
                <div class="progress-bar" [class.bg-warning]="winningOption?.id !== option.id" [class.bg-success]="winningOption?.id === option.id" role="progressbar" style="width: {{selectedSubject.voting_counter[option.name] * 100 / totalVotesCount}}%;" [attr.aria-valuenow]="selectedSubject.voting_counter[option.name] * 100 / totalVotesCount" aria-valuemin="0" aria-valuemax="100">
                  </div>
              </div><br/>

            </td>
            <td><br/>{{selectedSubject.voting_results[option.name]}}</td>
            <td>
              <br/>{{(totalVotesScore && totalVotesScore > 0 && selectedSubject.voting_results[option.name] ? selectedSubject.voting_results[option.name] * 100 / totalVotesScore : '0') | number: '1.0-2' }} %
              <div class="progress" *ngIf="totalVotesScore && totalVotesScore > 0 && selectedSubject.voting_results[option.name]">
                <div class="progress-bar " [class.bg-warning]="winningOption?.id !== option.id" [class.bg-success]="winningOption?.id === option.id" role="progressbar" style="width: {{selectedSubject.voting_results[option.name] * 100 / totalVotesScore}}%;" [attr.aria-valuenow]="selectedSubject.voting_results[option.name] * 100 / totalVotesScore" aria-valuemin="0" aria-valuemax="100">
                  </div>
              </div><br/>
            </td>            
          </tr>
          <tr>
            <th colspan="2">&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>

          <tr>
            <th colspan="2">Total</th>
            <th>{{totalVotesCount}}</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </table>        
      </div>
      <div class="card-text" style="text-align: left;"  *ngIf="!isAdmin">

        <div class="alert alert-dismissible alert-info">      
          <h4>Te rugam sa alegi mai jos optiunea ta de vot. Apasand butonul "Salveaza", optiunea ta va fi memorata. Votul este anonim.</h4><br />
          <h5><strong>Atentie, odata exprimata optiunea de vot, aceasta nu se poate schimba!</strong></h5>     
        </div>
        
        <div class="alert alert-dismissible alert-warning" *ngIf="selectedSubject.maximum_selections > 0">      
          <h5><strong>Poti alege pana la {{selectedSubject.maximum_selections}} optiuni!</strong></h5>                   
         </div>
         <br/>
         <fieldset>
          <div class="form-group" *ngIf="selectedSubject.maximum_selections <= 0">                                   
              <div class="custom-control custom-radio" *ngFor="let option of selectedSubject.options; let index = index">
                <input type="radio" id="item{{index}}" name="vote" class="custom-control-input" value="{{option.name}}" [(ngModel)]="selectedValue">
                <label class="custom-control-label" for="item{{index}}">{{option.name}}</label><br/><br/>
              </div>
            </div>

            <div class="form-group" *ngIf="selectedSubject.maximum_selections > 0">                                          
              <div class="custom-control custom-checkbox" *ngFor="let option of selectedSubject.options; let index = index">                
                <input type="checkbox" id="item{{index}}" name="vote{{index}}" class="custom-control-input" value="{{option.name}}" (click)="toggleValue(index)" 
                [attr.disabled]="selectedSubject.maximum_selections == selectedValuesLength && !selectedValues[index] ? true : null">
                <label class="custom-control-label" for="item{{index}}">{{option.name}}</label><br/><br/>                
              </div>
            </div>

            <!-- <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck1" checked="">
                <label class="custom-control-label" for="customCheck1">SACAREA Christian (academic)</label>
              </div><br/>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck2" checked="">
                <label class="custom-control-label" for="customCheck2">BRAD Stelian (academic)</label>
              </div><br/>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck3" checked="">
                <label class="custom-control-label" for="customCheck3">VAC Calin (academic)</label>
              </div><br/>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck4" checked="">
                <label class="custom-control-label" for="customCheck4">FLOREA Adrian (academic)</label>
              </div><br/>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck5" checked="">
                <label class="custom-control-label" for="customCheck5">NEAGOE Ioana (academic)</label>
              </div><br/>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck6" checked="">
                <label class="custom-control-label" for="customCheck6">IRSAY Miklos</label>
              </div><br/>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck7" checked="">
                <label class="custom-control-label" for="customCheck7">HOMORODEAN Daniel</label>
              </div><br/>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck8" checked="">
                <label class="custom-control-label" for="customCheck8">ORGHIDAN Radu</label>
              </div><br/>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck9" checked="">
                <label class="custom-control-label" for="customCheck9">SORLEA Ciprian</label>
              </div><br/>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck10" checked="">
                <label class="custom-control-label" for="customCheck10">PINTEA Ovidiu</label>
              </div><br/>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck11" checked="">
                <label class="custom-control-label" for="customCheck11">HEREA Bogdan</label>
              </div><br/>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck12" checked="">
                <label class="custom-control-label" for="customCheck12">CIOTLAUS Bogdan </label>
              </div><br/>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck13" checked="">
                <label class="custom-control-label" for="customCheck13">BARNA Erik</label>
              </div>
            </div> -->
          </fieldset>
          <div class="alert alert-dismissible alert-warning" *ngIf="selectedSubject.maximum_selections > 0 && selectedSubject.maximum_selections == selectedValuesLength">                  
            <h6>Ai atins numarul maxim de optiuni permise. Prin deselectarea optiunilor selectate, vei putea alege alte optiuni disponibile.</h6>
           </div>
          <button type="button" class="btn btn-primary" (click)="sendVote()">Salveaza</button>
          <a [routerLink]="['/ses', selectedSession.key]" class="btn btn-secundary">Inapoi</a>
        </div>        
    </div>
  </div>
  <a [routerLink]="['/ses', selectedSession.key]" class="btn btn-secundary">Inapoi</a>
</main>