<main class="form-signin" *ngIf="selectedSession">

<h5>{{selectedSession.name}}</h5><br/>
<h6 style="color:slategrey">{{currentUser.full_name}} ({{currentUser.company_name}})</h6><br/><br/>

<ul class="list-group">
    <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let subject of selectedSession.subjects" style="text-align: left;">
      <a [routerLink]="['/sbj', subject.key]" class="flex-grow-1" *ngIf="subject.subject_status === 'started' || isAdmin">        
        {{subject.name}}
        <span style="color: lightslategray" *ngIf="isAdmin && subject.subject_status !== 'pending' && subject.subject_status !== 'draft' && subject.total_votes">[{{subject.total_votes}} voturi]</span>
      </a>

      <span class="flex-grow-1" style="color:lightgray" *ngIf="subject.subject_status !== 'started' && !isAdmin">        
        {{subject.name}}
      </span>
      
      <span style="color:lightgray; padding: 0 10px 0 10px;" *ngIf="subject.subject_status === 'pending'">[&nbsp;&nbsp;Vot&nbsp;In&nbsp;Asteptare&nbsp;&nbsp;]</span>
      <span style="color:lightgray; padding: 0 10px 0 10px;" *ngIf="subject.subject_status === 'started'">[&nbsp;&nbsp;Vot&nbsp;Deschis&nbsp;&nbsp;]</span>
      <span style="color:lightgray; padding: 0 10px 0 10px;" *ngIf="subject.subject_status === 'ended'">[&nbsp;&nbsp;Vot&nbsp;Finalizat&nbsp;&nbsp;]</span>
      <span style="color:lightgray; padding: 0 10px 0 10px;" *ngIf="subject.subject_status === 'draft'">[&nbsp;&nbsp;Vot&nbsp;Inchis&nbsp;&nbsp;]</span>
      <span style="color:lightgray; padding: 0 10px 0 10px;" *ngIf="subject.subject_status === 'voted'">[&nbsp;&nbsp;Vot&nbsp;Exprimat&nbsp;&nbsp;]</span>

      <span class="badge badge-success" width="16" height="16" *ngIf="subject.subject_status === 'draft'">
        <img src="/assets/icons/clock.svg" alt="Bootstrap" width="16" height="16">
      </span>
      <span class="badge badge-primary" width="16" height="16" *ngIf="subject.subject_status === 'pending'">
          <img src="/assets/icons/lock.svg" alt="Bootstrap" width="16" height="16"> 
      </span>
      <span class="badge badge-info" width="16" height="16" *ngIf="subject.subject_status === 'started'">
        <img src="/assets/icons/unlock.svg" alt="Bootstrap" width="16" height="16">
      </span>      
      <span class="badge badge-success" width="16" height="16" *ngIf="subject.subject_status === 'voted'">
        <img src="/assets/icons/bookmark-check.svg" alt="Bootstrap" width="16" height="16" >
      </span>      

      <span class="badge badge-success" width="16" height="16" *ngIf="subject.subject_status === 'ended' && !isAdmin">
        <img src="/assets/icons/bookmark-x.svg" alt="Bootstrap" width="16" height="16" >
      </span>      
      <span class="badge badge-success" width="16" height="16" *ngIf="subject.subject_status === 'ended' && isAdmin">
        <img src="/assets/icons/bookmark-check.svg" alt="Bootstrap" width="16" height="16" >
      </span>      

  

      
      <a href="javascript:;"  class="btn btn-sm btn-primary" (click)="startSubject(subject)" *ngIf="isAdmin && selectedSession.session_status === 'started' && subject.subject_status === 'pending'" style="margin-left: 10px;">        
        <img src="/assets/icons/play.svg" alt="Bootstrap" width="16" height="16">        
      </a>

      <a href="javascript:;"  class="btn btn-sm btn-primary" (click)="stopSubject(subject)" *ngIf="isAdmin && selectedSession.session_status === 'started' && subject.subject_status === 'started'" style="margin-left: 10px;">        
        <img src="/assets/icons/stop-circle.svg" alt="Bootstrap" width="16" height="16">        
      </a>
      <!-- <span class="badge badge-primary" *ngIf="isAdmin && selectedSession.session_status === 'started' && subject.subject_status === 'ended'" style="margin-left: 10px;">
        <a href="javascript:;">
        <img src="/assets/icons/stop-circle.svg" alt="Bootstrap" width="16" height="16">
        </a>
      </span> -->
    </li>    
  </ul>
  <a routerLink="/" class="btn btn-secundary">Inapoi</a>
</main>